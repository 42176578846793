<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{
          backgroundImage:
            'url(' + $store.state.fastPlataforma.banner_plataforma + ')',
          backgroundColor: '#000',
        }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{
                  color: $store.state.fastPersonalizacao.titulo_banner_color
                    ? $store.state.fastPersonalizacao.titulo_banner_color
                    : '#fff',
                  textShadow: '1px 1px #000',
                }"
              >
                {{
                  $store.state.fastPersonalizacao.titulo_banner
                    ? $store.state.fastPersonalizacao.titulo_banner
                    : ""
                }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Minhas turmas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section
                            class="fd-app-welcome text-left fast-plataforma-iuea-hide"
                          >
                            <h1
                              class="aluno_font_color"
                            >
                              Minhas
                              <span>turmas</span>
                            </h1>
                           
                            <p                            
                              class="aluno_font_color"
                            >
                              Veja as turmas em que você está matriculado
                            </p>
                            <div
                              class="fd-welcome-form-effect fast-plataforma-iuea-hide"
                            >
                              <img
                                :src="
                                  require('@/assets/images/form-effect-01.png')
                                "
                                class="mt-2"
                              >
                            </div>
                          </section>

                          <!-- /fd-app-welcome -->
                          <div class="row">
                            <div
                              class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1"
                            >
                              <h4
                                class="aluno_font_color"                                 
                              >
                                Lista de turmas ({{ fastAlunoTurmasFiltro.length }})
                              </h4>                                
                            </div>
                            <div class="col-12 table-responsive">  
                              <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      Matrícula
                                    </th>
                                    <th
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      Acesso
                                    </th> 
                                    <th
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      Curso
                                    </th>
                                    <th class="text-center">
                                      Turma
                                    </th>  
                                    <th
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      Turno
                                    </th>                                         
                                    <th
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      Ativo
                                    </th>
                                    <th class="text-center">
                                      Ações
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastAlunoTurmasFiltro.length">
                                  <tr
                                    v-for="(matricula, index) in fastAlunoTurmasFiltro"
                                    :key="index"
                                  >
                                    <td
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      #{{ matricula.id_matricula }}
                                    </td>
                                    <td
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      <div v-if="matricula.matricula_por_programa">
                                        <span v-if="matricula.qtde_modulos_compra == 1">1 módulo</span>
                                        <span v-else-if="matricula.qtde_modulos_compra > 1">{{ matricula.qtde_modulos_compra }} módulos</span>
                                        <span v-else>Nenhum módulo</span>
                                      </div>
                                      <div v-else-if="matricula.matricula_por_unidade">
                                        <span v-if="matricula.qtde_unidades_compra == 1">1 unidade curricular</span>
                                        <span v-else-if="matricula.qtde_unidades_compra > 1">{{ matricula.qtde_unidades_compra }} unidade(s) curricular(es)</span>
                                        <span v-else>Nenhuma unidade curricular</span>                                            
                                      </div>
                                      <div v-else>
                                        Curso completo
                                      </div>
                                    </td>
                                    <td
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      {{ matricula.nome_curso }}
                                    </td>
                                    <td class="text-center">
                                      {{ matricula.sigla_turma }}
                                    </td>
                                    <td
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      <span v-if="matricula.turno == 1">Manhã</span>
                                      <span v-else-if="matricula.turno == 2">Tarde</span>
                                      <span v-else-if="matricula.turno == 3">Noite</span>
                                      <span v-else>Indefinido</span>
                                    </td>
                                    <td
                                      v-if="!isMobile()"
                                      class="text-center"
                                    >
                                      <button
                                        v-if="matricula.ativo"
                                        class="btn btn-sm btn-success pt-0 pb-0"
                                      >
                                        <small>Sim</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                      >
                                        <small>Não</small>
                                      </button>
                                    </td>
                                    <td class="text-center">
                                      <a
                                        v-if="matricula.ativo"
                                        :href="
                                          '/plataforma/' +
                                            parseInt($route.params.id_plataforma) +
                                            '/aluno/turma/' +
                                            matricula.id_turma + '?matricula=' + base64encode(matricula.id_matricula)
                                        "
                                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                      >
                                        <small class="text-white">Acessar</small>
                                      </a>
                                      <small
                                        v-else
                                        class="btn btn-sm btn-secondary pt-0 pb-0"
                                      >
                                        Sem acesso
                                      </small>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastAlunoTurmasLoading">
                                  <tr>
                                    <td
                                      colspan="7"
                                      class="text-center"
                                    >
                                      <span>Carregando turmas...</span>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                      colspan="7"
                                      class="text-center"
                                    >
                                      <span>Você não está matriculado em nenhuma turma</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="10"
                              :items="fastAlunoTurmasFiltro"
                              @changePage="pageOfTurmas = $event"
                            />
                          </div>
                        </div>
                        <div class="col-12 mt-4">
                          <a
                            class="btn btn-red-hollow fast-iuea-ml-20"
                            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                          >
                            <b-icon-arrow-return-left /> Voltar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAlunoTurmas",
  components: {
    Pagination,
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Lives
      fastAlunoTurmasTotal: [],
      fastAlunoTurmasFiltro: [],
      pageOfTurmas: [],
      fastAlunoTurmasLoading: true
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          console.log("this.$store.state.fastUsuario.Id_pessoa", this.$store.state.fastPermissoes.id_pessoa)
          this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.$store.state.fastPermissoes.id_pessoa)
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastMatriculaAluno(id_plataforma, id_pessoa_aluno) {
      this.fastAlunoTurmasLoading = true
      this.fastAlunoTurmasTotal = [];
      this.fastAlunoTurmasFiltro = [];
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista", "id_plataforma=" + id_plataforma + "&id_programa=0&id_pessoa_aluno=" + id_pessoa_aluno).then(obj => {
        console.log("getFastMatriculasAluno", obj);  
        const matriculas = []       
        if (obj.length) {
          obj.forEach(m => {
            if (m.ativo) {
              matriculas.push(m)
            }
          });
          this.fastAlunoTurmasTotal = matriculas; 
          this.fastAlunoTurmasFiltro = matriculas; 
        } 
         this.fastAlunoTurmasLoading = false
      }).catch(e => {
        console.log(e);
        this.fastAlunoTurmasLoading = false
      })
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'E':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
